import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import A1 from '../../images/A1.jpg'
import A2 from '../../images/A2.webp'
import A3 from '../../images/A3.jpg'
import './AreasAtuacao.css';

function AreasAtuacao() {
  return (
    <>
    <a id='servicos'></a>
     <Container fluid className='areaAtucao-container'>
        <Container>
            <Row  className='areaAtuacao-containerNames'>
                <Col md={6} className='box-adv'>
                    <h1 className='areaAtuacao-adv-t1'>Alessandra Drumond</h1>
                    <h6 className='areaAtuacao-adv-t2'>Advogada OAB/MG 138.103</h6>
                </Col>
                <Col md={6} className='box-adv'>
                    <h1 className='areaAtuacao-adv-t1'>Thiago Crivellari</h1>
                    <h6 className='areaAtuacao-adv-t2'>Advogado OAB/MG 218.009</h6>
                </Col>
            </Row>
        </Container>

        <Row className='areaAtuacao-containerAreas'>
         <Col style={{textAlign:"center"}} md={12}>
            <p style={{color:"#A53B47",fontSize:"1.4rem", fontWeight:"700",marginTop:"-70px"}}>Alguns dos nossos serviços</p>
            <h1 style={{color:"#fff", fontWeight:"800"}}>Áreas de atuação</h1>
         </Col>
        </Row>
        <Container className='d-flex containerImages mb-2'>
            <Col md={4} className='c-At'>
              <img className='mb-1 foto' src={A1} ></img>
              <p style={{color:"#fff", fontSize:"1rem",textAlign:"left",alignSelf:"flex-start",fontWeight:"700"}}>Direito Previdenciário</p>
              <p style={{color:"#fff", fontSize:"1rem"}}>O direito previdenciário cuida da seguridade social, garantindo benefícios como aposentadoria e auxílio-doença aos trabalhadores. No Brasil, o INSS administra esses benefícios.</p>
            </Col>
            <Col md={4} className='c-At'>
              <img className='mb-1 foto' src={A2}></img>
              <p style={{color:"#fff", fontSize:"1rem",textAlign:"left",alignSelf:"flex-start",fontWeight:"700"}}>Direito Civil</p>
              <p style={{color:"#fff"}}>O direito civil regula as relações entre pessoas, abrangendo questões como contratos, família, propriedade e obrigações. Ele estabelece direitos e deveres dos indivíduos na vida cotidiana.</p>
            </Col>
            <Col md={4} className='c-At'>
              <img className='mb-1 foto' src={A3}></img>
              <p style={{color:"#fff", fontSize:"1rem",textAlign:"left",alignSelf:"flex-start",fontWeight:"700"}}>Direito Trabalhista</p>
              <p style={{color:"#fff"}}>O direito trabalhista regula as relações entre empregadores e empregados, assegurando direitos como salário, jornada de trabalho, férias e condições de trabalho.</p>
            </Col>

        </Container>
     </Container>
    </>
  );
}

export default AreasAtuacao;