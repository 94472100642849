import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './SobreNos.css'
import FT1 from '../../images/FT1.jpg'
import FT2 from '../../images/FT2.jpg'
import { useInView } from "react-intersection-observer";
import animacoes from '../../helpers/Anins';
import 'animate.css'
// import { Container } from './styles';

function SobreNos() {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        triggerOnce: true
      });
      console.log(inView)

    return (
    <>
    <a  id='sobre'></a>
        <Container className={`sobre-nos`}>
            <Row>
                <Col className={`canvas-images ${inView ? animacoes.esquerda : ""}`}>
                <img  className='image1' src={FT1} />
                <img  className='image2' src={FT2} />
                </Col>
                <Col className='text-sobre-nos'>
                <Container className={`${inView ? animacoes.direita : ""}`}>

                        <h6 ref={ref} className=' header-sobrenos'>Sobre Nós</h6>
                        <h1 className='title-sobrenos'>Advogados especializados</h1>
                        <p className='text-sobre'>


                        O Escritório <span style={{fontStyle:'italic'}}>Drumond&Crivellari</span> se dedica a atuar em diversas áreas do direito, oferecendo consultoria e suporte jurídico tanto para empresas quanto para pessoas físicas. Nossa equipe é focada em fornecer um atendimento de excelência e personalizado para atender às necessidades específicas de cada cliente. Acreditamos em uma abordagem acessível e humana, comprometida com a resolução eficaz dos problemas legais de nossos clientes.

Nossa missão é proporcionar segurança e tranquilidade aos nossos clientes, guiando-os em cada etapa dos processos jurídicos com clareza e eficiência. Trabalhamos com ética, transparência e dedicação para construir relações de confiança e respeito mútuo.

                        </p>
                </Container>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default SobreNos;